(function ($) {
  $(window).load(function () {
    setTimeout(() => {
      if ($(window).width() < 768) {
        return;
      }

      if (
        (-1 !== document.location.search.indexOf('form[sent]') && -1 !== document.location.search.indexOf('form%5Bsent%5D'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $stickyHeader = $('.header');
      var $message = $('.form_success_message');

      if (!$stickyHeader.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $stickyHeader.outerHeight() - 25;

      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({scrollTop: $message.offset().top - $stickyHeader.outerHeight() - 25});
    }, 500);

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });
  });

  $(document).ready(function () {
    var windowWidth = $(window).width();

    $(window).scroll(function () {
      if ($(window).scrollTop() > 0) {
        $('body').addClass('sticky');
        $('.header').addClass('sticky');
      } else {
        $('body').removeClass('sticky');
        $('.header').removeClass('sticky');
      }
    });


    $('a[data-fancybox]').fancybox({
      loop: true,
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    $('.navbar-toggler').on('click', function () {
      $(this).parent().parent().toggleClass('open-menu');
    });
    $('.gallery-popup-button').on('click', function () {
      $(this).parent().parent().children().first().find('a').click();
    });
  });
  $(window).on('load', function () {
    if (window.location.hash) {
      $target = window.location.hash;
      console.log($target);
      $('html,body').animate({
        scrollTop: $($target).offset().top
      }, 1000);
    }
  });
})(jQuery);
